<script lang="ts">
  import CustomTabs from 'scripts/global_components/CustomTabs.svelte';
  import SlipTable from './SlipTable.svelte';
  import TabPanel from 'scripts/global_components/child_components/TabPanel.svelte';
  import { navigate } from 'svelte-routing';
  import {
    purchasesPageCondition,
    slipTableConfig,
    slipTypeChoice,
    slipTypeChoiceInverse,
  } from './config/store';
  import AdvancedSearchForm from 'scripts/global_components/child_components/AdvancedSearchForm.svelte';
  import type {
    ChoiceDisplayMap,
    FormConfig,
    SearchParamObj,
    SimpleFormConfig,
  } from 'scripts/utils/types';
  import {
    makeConfigFromOptions,
    searchParamToQueryParams,
  } from 'scripts/utils/form_utils';
  import { routeStockerApp } from 'scripts/router';
  import { rebuildSearchParamObj } from 'scripts/utils/commonStates';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import { defaultFieldOptions } from 'scripts/utils/utils';
  import Spinner from 'scripts/global_components/child_components/Spinner.svelte';

  const tabs = Object.values($slipTypeChoice);

  let searchParamObj: SearchParamObj;
  let searchFields;
  let slipList = [];
  let choiceDisplayMap: ChoiceDisplayMap = {};

  let spinnerDisplay = false;

  let slipFormConfig: FormConfig = {};
  let simpleFormConfig: SimpleFormConfig = {
    Row_1: {
      type: {
        name: '種類',
        inputClass: 'col-12 col-md-4',
      },
      number: {
        name: '伝票番号',
        inputClass: 'col-12 col-md-4',
      },
      date_created: {
        name: '作成日',
        inputClass: 'col-12 col-md-4',
        options: { ...defaultFieldOptions, label: '作成日' },
      },
    },
    Row_2: {
      order: {
        name: '売上',
        inputClass: 'col-12 col-md-4',
      },
    },
    Row_3: {
      slipitem_set: {
        name: '内訳',
        inputClass: 'col-12',
        options: { label: '伝票内訳' },
      },
    },
  };

  const getDataSets = async (type: string) => {
    const configRes = await makeConfigFromOptions(
      routeStockerApp.slipApi(),
      simpleFormConfig,
      $slipTableConfig,
    );
    slipFormConfig = configRes.formConfig;
    $slipTableConfig = configRes.tableConfig;
    choiceDisplayMap = configRes.choiceDisplayMap;
    const rebuilt = rebuildSearchParamObj(
      configRes.searchParamObj,
      $purchasesPageCondition.searchParamObj,
      searchFields,
    );
    searchParamObj = rebuilt.searchParamObj;
    searchFields = rebuilt.searchFields;
    getSlips();
  };

  const getSlips = async () => {
    spinnerDisplay = true;
    const slip_type = $slipTypeChoiceInverse[$purchasesPageCondition.activeTab];
    let params = {
      ...searchParamToQueryParams(
        $purchasesPageCondition.searchParamObj,
        searchFields,
      ),
      slip_type: slip_type,
    };
    //console.log(params);
    const res = await fetchGetRequest({
      endpoint: routeStockerApp.slipApi(),
      errorMessage: '伝票の取得に失敗しました。',
      params: params,
    });
    if (res.success) slipList = res.data;
    spinnerDisplay = false;
  };

  $: getDataSets($purchasesPageCondition.activeTab);

  //$: console.log($purchasesPageCondition);

  const onAdavancedSearch = (event) => {
    // console.log(event.detail);
    $purchasesPageCondition.searchParamObj = event.detail;
    getSlips();
  };
</script>

<CustomTabs {tabs} bind:active={$purchasesPageCondition.activeTab}>
  <div class="mt-2 px-2">
    <AdvancedSearchForm
      bind:searchParamObj
      bind:searchFields
      formConfig={slipFormConfig}
      hiddenFields={['order', 'type', 'slipitem_set']}
      on:searchClick={onAdavancedSearch}
    />
  </div>
  {#each Object.entries($slipTypeChoice) as choice}
    <TabPanel name={choice[1]} bind:active={$purchasesPageCondition.activeTab}>
      <SlipTable bind:slipList bind:choiceDisplayMap />
    </TabPanel>
  {/each}
</CustomTabs>
<div class="row my-3">
  <div class="col-12 d-flex justify-content-center">
    <button class="btn btn-primary" on:click={(e) => navigate('slip/')}>
      新規伝票作成
    </button>
  </div>
</div>
<Spinner bind:display={spinnerDisplay} />
